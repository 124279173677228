import { ICreateOrder, IOrder, IOrderPayment, IProducts, IResponse } from './types';
import { API_BASE_URL, API_MOCK_URL } from './config';
import ApiClient from './ApiClient';
import { ApiVersion } from '../utils/constants/config';

export const getOrder = (config: IOrder) =>
  ApiClient.post<IResponse<IOrderPayment>>(`${API_BASE_URL}/${ApiVersion.baseService}/orders`, config);

export const getOrderInform = (id: number) =>
  ApiClient.get<IResponse<IOrderPayment>>(`${API_BASE_URL}/${ApiVersion.baseService}/orders/${id}`);

export const getProduct = (id: number, promocode?: string) =>
  ApiClient.get<IResponse<IProducts>>(
    `${API_BASE_URL}/${ApiVersion.baseService}/products/${id}?platform=landing&promocode=${promocode}`
  );

export const getProducts = () =>
  ApiClient.get<IResponse<IProducts[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/products?platform=web`);

export const createOrder = (config: ICreateOrder) =>
  ApiClient.post<IResponse<IOrderPayment>>(`${API_BASE_URL}/${ApiVersion.baseService}/orders/landing`, config);

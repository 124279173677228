import { AxiosError } from 'axios';
import { IResponseError } from '../api/types';

const checkActionStatus = (actionType: string, status: string, name: string) =>
  actionType.startsWith(name) && actionType.endsWith(status);

export const matcherHelper = {
  isPendingAction: (actionType: string, name: string) => {
    return checkActionStatus(actionType, 'pending', name);
  },

  isRejectedAction: (actionType: string, name: string) => {
    return checkActionStatus(actionType, 'rejected', name);
  },
};

const isIResponseError = (object: any): object is IResponseError => {
  return 'error' in object && 'error_description' in object && 'message' in object;
};

export const getTextError = (error: unknown): string => {
  if (typeof error === 'string') {
    return error;
  } else if (error instanceof AxiosError) {
    return error.response?.data?.message;
  } else if (error instanceof Error) {
    return error.message;
  } else if (error && typeof error === 'object' && isIResponseError(error)) {
    return error.message;
  } else {
    console.error(error);
    throw new Error('Unknown error type!!!');
  }
};

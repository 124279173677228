import React from 'react';
import { FC } from 'react';

enum IconType {
  medium = 'medium',
  stroke = 'stroke',
}

interface IProps {
  type: 'medium' | 'stroke';
}

export const SuccessIcon: FC<IProps> = ({ type }) => {
  return (
    <>
      {type === IconType.medium && (
        <svg width="91" height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M79.5 45C79.5 63.7777 64.2777 79 45.5 79C26.7223 79 11.5 63.7777 11.5 45C11.5 26.2223 26.7223 11 45.5 11C64.2777 11 79.5 26.2223 79.5 45Z"
            fill="#ADFA2E"
          />
          <path
            d="M63.2554 30.5764C62.3487 29.7296 60.9702 29.8272 60.1763 30.7943L40.5382 54.7647L30.7909 43.5171C29.977 42.5693 28.5967 42.5047 27.7081 43.373C26.8195 44.2412 26.759 45.7135 27.573 46.6613L38.1432 58.867C39.3642 60.2888 41.4345 60.3855 42.7674 59.0832C42.8327 59.017 42.8653 58.9839 42.8968 58.9496C42.9282 58.9153 42.9585 58.8797 43.0191 58.8086L63.4596 33.8606C64.2535 32.8935 64.162 31.4231 63.2554 30.5764Z"
            fill="white"
          />
        </svg>
      )}

      {type === IconType.stroke && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM16.6607 8.25136C16.2451 7.88754 15.6134 7.92946 15.2496 8.34498L10.7332 13.5033L8.73739 11.3245C8.36434 10.9173 7.73178 10.8896 7.32454 11.2626C6.91729 11.6357 6.88957 12.2682 7.26262 12.6755L9.63551 15.2659C10.1951 15.8767 11.1439 15.9183 11.7548 15.3588C11.7847 15.3303 11.7997 15.3161 11.8141 15.3014C11.8285 15.2866 11.8424 15.2713 11.8701 15.2408L16.7543 9.66247C17.1181 9.24695 17.0762 8.61517 16.6607 8.25136Z"
            fill="#1D1D1D"
          />
        </svg>
      )}
    </>
  );
};

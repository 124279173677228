import { configureStore, ThunkAction, Action, combineReducers, Reducer } from '@reduxjs/toolkit';
import mainReducer from './main/slice';
import themeReducer from './theme/slice';
import orderReducer from './order/slice';
import notificationStore from './notifications/slice';

const combineReducer = combineReducers({
  mainStore: mainReducer,
  themeStore: themeReducer,
  orderStore: orderReducer,
  notificationsStore: notificationStore,
});

const rootReducer: Reducer = (state: RootState, action: Action) => {
  if (action.type === 'user/removeUser') {
    state = {} as RootState;
  }
  return combineReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combineReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

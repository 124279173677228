//test
export const API_BASE_URL = 'https://back.test.kolsanovafit.ru';
export const API_NOTIFICATION_URL = 'https://back-notification.test.kolsanovafit.ru';
export const API_APP_URL = 'https://back-app.test.kolsanovafit.ru';
export const API_MOCK_URL = 'http://localhost:8080';

//live
/*export const API_BASE_URL = 'https://back-users.app.kolsanovafit.ru';
export const API_NOTIFICATION_URL = 'https://back-notification.app.kolsanovafit.ru';
export const API_APP_URL = 'https://back-app.app.kolsanovafit.ru';
*/
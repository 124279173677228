import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from '../../src/routes';
import { Provider as ReduxProvider } from 'react-redux';
import { PagePositionProvider } from '../context/PagePositionContext';
import ThemeProvider from '../components/layouts/ThemeProvider';
import { ServiceNotifications } from '../components/organisms/ServiceNotifications';
import { store } from '../redux/store';
import '../styles/global.scss';
import { ModalProvider } from '../context/ModalContext';

function App() {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <ThemeProvider theme="light">
          <PagePositionProvider>
            <ServiceNotifications />
            <Router />
          </PagePositionProvider>
        </ThemeProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MainPage, ListTariffsPage, UserAgreementPage, OfferPage, PaymentMethodPage } from '../pages';
import { Paths } from '../utils/constants/config';

const { main, listTariffs, paymentOrder, userAgreement, offer, paymentMethod } = Paths;

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={paymentOrder} replace />} />
      <Route path={paymentOrder} element={<MainPage />} />
      <Route path={`${paymentOrder}/:id/`} element={<MainPage />} />
      <Route path={userAgreement} element={<UserAgreementPage />} />
      <Route path={offer} element={<OfferPage />} />
      <Route path={`${paymentMethod}/:id/:name/:email/:promocode?`} element={<PaymentMethodPage />} />
    </Routes>
  );
};

export default Router;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { matcherHelper } from '../utils';
import { getStatusOrderThunk, getProductThunk, getProductsThunk } from './thunks';
import { IOrderPayment, IProducts } from '../../api/types';

interface IOrder {
  product: IProducts | null;
  products: IProducts[];
  error: string;
  isLoading: boolean;
  order: IOrderPayment | null;
  payLink: string;
}

const initialState: IOrder = {
  product: null,
  products: [],
  error: '',
  isLoading: false,
  order: null,
  payLink: '',
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearProduct: (state) => {
      state.product = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatusOrderThunk.fulfilled, (state, { payload }: PayloadAction<IOrderPayment>) => {
        state.error = '';
        state.isLoading = false;
        state.order = payload;
      })
      .addCase(getProductThunk.fulfilled, (state, { payload }: PayloadAction<IProducts>) => {
        state.error = '';
        state.isLoading = false;
        state.product = payload;
      })
      .addCase(getProductsThunk.fulfilled, (state, { payload }: PayloadAction<IProducts[]>) => {
        state.error = '';
        state.isLoading = false;
        state.products = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, orderSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, orderSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const { clearProduct } = orderSlice.actions;

export default orderSlice.reducer;

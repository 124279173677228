import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification } from '../../api/types';
import { matcherHelper } from '../utils';
import { clearServiceNotice, getNotificationsThunk } from './thunks';

export type TNoticeType = 'warning' | 'success' | 'error';

export interface IServiceNotice {
  id: string;
  text: string;
  type: TNoticeType;
  sleepTime: number;
}

interface INotifications {
  serviceNotifications: IServiceNotice[];
  notifications: INotification[];
  error: string;
  isLoading: boolean;
}

const initialState: INotifications = {
  serviceNotifications: [],
  notifications: [],
  error: '',
  isLoading: false,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    createServiceNotice(state, { payload: notice }: PayloadAction<IServiceNotice>) {
      const isHaveNotice = state.serviceNotifications.find((note) => notice.text === note.text);

      if (!isHaveNotice) {
        state.serviceNotifications.push(notice);
      }
    },
    addNotification(state, { payload }: PayloadAction<INotification>) {
      state.notifications = [...state.notifications, payload];
    },
    changeNotification(state, { payload }: PayloadAction<INotification>) {
      const result = state.notifications.map((notification) => {
        if (notification.id === payload.id) {
          return payload;
        }

        return notification;
      });

      state.notifications = result;
    },
    readAllNotifications(state) {
      const result = state.notifications.map((notification) => ({ ...notification, readed_at: 'readed' }));

      state.notifications = result;
    },
    removeNotification(state, { payload }: PayloadAction<number>) {
      if (!state.notifications.length) return;

      const result = state.notifications.filter(({ id }) => id !== payload);

      state.notifications = result;
    },
    removeAllNotifications(state) {
      if (!state.notifications.length) return;

      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearServiceNotice.fulfilled, (state, { payload: noticeId }: PayloadAction<string>) => {
        state.serviceNotifications = state.serviceNotifications.filter((notice) => notice.id !== noticeId);
      })
      .addCase(getNotificationsThunk.fulfilled, (state, { payload }: PayloadAction<INotification[]>) => {
        state.error = '';
        state.isLoading = false;
        state.notifications = payload;
      })
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, notificationsSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, notificationsSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const {
  createServiceNotice,
  removeNotification,
  addNotification,
  changeNotification,
  readAllNotifications,
  removeAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMain {}

const initialState: IMain = {};

export const main = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = main.actions;

export default main.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTextError } from '../utils';
import { getOrderInform, createOrder, getProduct, getProducts } from '../../api/order';
import { ICreateOrder } from '../../api/types';
import { clearProduct } from './slice';

export const getStatusOrderThunk = createAsyncThunk(
  'order/getStatusOrderThunk',
  async (id: number, { rejectWithValue }) => {
    try {
      return (await getOrderInform(id)).data.data;
    } catch (error) {
      const textError = getTextError(error);
      return rejectWithValue(textError);
    }
  }
);

export const createProductThunk = createAsyncThunk(
  'order/createProductThunk',
  async (config: ICreateOrder, { rejectWithValue }) => {
    try {
      return (await createOrder(config)).data.data;
    } catch (error) {
      const textError = getTextError(error);
      return rejectWithValue(textError);
    }
  }
);

export const getProductThunk = createAsyncThunk(
  'products/getProductThunk',
  async ({ id, promocode }: { id: number; promocode?: string }, { rejectWithValue, dispatch }) => {
    try {
      return (await getProduct(id, promocode)).data.data;
    } catch (error) {
      dispatch(clearProduct());
      const textError = getTextError(error);
      return rejectWithValue(textError);
    }
  }
);

export const getProductsThunk = createAsyncThunk('products/getProductsThunk', async (_, { rejectWithValue }) => {
  try {
    return (await getProducts()).data.data;
  } catch (error) {
    const textError = getTextError(error);
    return rejectWithValue(textError);
  }
});

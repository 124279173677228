export enum TypesCardValues {
  mir = 'Mir',
  maestro = 'Maestro',
  visa = 'Visa',
  masterCard = 'MasterCard',
}

export enum Paths {
  main = '/',
  listTariffs = '/list_tariffs',
  paymentOrder = '/payment_order',
  userAgreement = '/user_agreement',
  offer = '/offer',
  paymentMethod = '/payment_method',
}

export enum ApiVersion {
  baseService = 'api/v1',
  videoService = 'api/v1',
  analyticsService = 'api/v1',
  appService = 'api/v1',
  notificationsService = 'api/v1',
}

export enum ValuesOfConstants {
  licenseAgreement = 'license-agreement',
  userAgreement = 'user-agreement',
  privacyPolicy = 'privacy-policy',
  offer = 'offer-link',
}

export enum ErrorMessages {
  promocodeLimitExceeded = 'Промокод недействителен',
  promocodeExpired = 'Срок действия истек',
  promocodeNotFitAnotherConditions = 'Промокод не найден',
  defaultTextError = 'Ошибка, не удалось выполнить действие!',
}

import React, { FC, ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setTheme } from '../../../redux/theme/slice';

export type TTheme = 'light' | 'dark';

export interface IProps {
  theme: TTheme;
  children: ReactNode;
}

const applyTheme = (selectedTheme: TTheme) => {
  const root = document.querySelector('html')!;

  root.dataset.theme = selectedTheme;
};

const ThemeProvider: FC<IProps> = ({ children, theme: defaultTheme }) => {
  const {
    themeStore: { theme },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const initialize = () => {
    applyTheme(defaultTheme);

    dispatch(setTheme(defaultTheme));
  };

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  useEffect(() => {
    initialize();
  }, []);

  return <>{children}</>;
};

export default ThemeProvider;

import React, { FC } from 'react';
import { useAppDispatch } from '../../../hooks/redux';
import { IServiceNotice } from '../../../redux/notifications/slice';
import { clearServiceNotice } from '../../../redux/notifications/thunks';
import s from './styles.module.scss';
import { NoticeTypes } from './config';
import { getIsValue } from '../../../utils/getIsValue';
import { Stack } from '@mui/material';
import { useEffectOnce } from 'usehooks-ts';
import { ErrorIcon } from '../../../assets/icons/ErrorIcon';
import { SuccessIcon } from '../../../assets/icons/SuccessIcon';
import cn from 'classnames';
import { Typography } from 'front-package-ui-kit';

interface IProps extends IServiceNotice {}

const Notice: FC<IProps> = ({ id, text, type, sleepTime }) => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(clearServiceNotice({ id, sleepTime }));
  });

  return (
    <>
      {getIsValue(type, NoticeTypes.error) && (
        <div className={s.notice}>
          <Stack direction="row" columnGap="10px" className={cn(s.noticeContent, s.error)}>
            <ErrorIcon />

            <Typography color="white" variant="text4">
              {text}
            </Typography>
          </Stack>
        </div>
      )}

      {getIsValue(type, NoticeTypes.success) && (
        <div className={s.notice}>
          <Stack direction="row" columnGap="10px" className={cn(s.noticeContent, s.success)}>
            <SuccessIcon type="stroke" />

            <Typography variant="text4">{text}</Typography>
          </Stack>
        </div>
      )}
    </>
  );
};

export default Notice;

import { ApiVersion } from '../utils/constants/config';
import ApiClient from './ApiClient';
import { API_NOTIFICATION_URL } from './config';
import { INotification, IResponse } from './types';

export const getNotifications = () =>
  ApiClient.get<IResponse<INotification[]>>(
    `${API_NOTIFICATION_URL}/${ApiVersion.notificationsService}/notifications/me`
  );

export const readNotification = (id: number) =>
  ApiClient.put<IResponse<0 | 1>>(`${API_NOTIFICATION_URL}/${ApiVersion.notificationsService}/notifications/${id}`);

export const removeNotification = (id: number) =>
  ApiClient.delete(`${API_NOTIFICATION_URL}/${ApiVersion.notificationsService}/notifications/${id}`);

import React, { lazy, Suspense } from 'react';

const LazyUserAgreement = lazy(() => import('./UserAgreement'));

const UserAgreementPage = () => (
  <Suspense fallback={<></>}>
    <LazyUserAgreement />
  </Suspense>
);

export default UserAgreementPage;

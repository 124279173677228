import React, { lazy, Suspense } from 'react';

const LazyOffer = lazy(() => import('./Offer'));

const OfferPage = () => (
  <Suspense fallback={<></>}>
    <LazyOffer />
  </Suspense>
);

export default OfferPage;

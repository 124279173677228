import React, { lazy, Suspense } from 'react';

const LazyPaymentMethod = lazy(() => import('./PaymentMethod'));

const PaymentMethodPage = () => (
  <Suspense fallback={<></>}>
    <LazyPaymentMethod />
  </Suspense>
);

export default PaymentMethodPage;

import React, { lazy, Suspense } from 'react';

const LazyMain = lazy(() => import('./Main'));

const MainPage = () => (
  <Suspense>
    <LazyMain />
  </Suspense>
);

export default MainPage;

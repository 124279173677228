import React, { createContext, FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const TEST_ID = 'PagePositionContext';

type TPosition = Record<string, string>;

type TPositions = Record<string, TPosition>;

interface IPagePositionContext {
  savePositions: (position: TPosition) => void;
  getPositions: () => TPosition | undefined;
}

const PagePositionContext = createContext<IPagePositionContext | null>(null);

interface IProps {
  children: ReactNode;
}

const PagePositionProvider: FC<IProps> = ({ children }) => {
  const [pagePositions, setPagePositions] = useState<TPositions>({});

  const { pathname } = useLocation();

  const savePositions = (position: TPosition) => {
    setPagePositions((prev) => ({ ...prev, [pathname]: position }));
  };

  const getPositions = () => {
    return pagePositions[pathname];
  };

  return (
    <PagePositionContext.Provider value={{ savePositions, getPositions }}>{children}</PagePositionContext.Provider>
  );
};

export { PagePositionContext, PagePositionProvider };
